import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_common/layout";
import { clearPushNotificationApi } from "../_redux/authCrud";
import * as auth from "../_redux/authRedux";
class Logout extends Component {
  componentDidMount() {
    this.props.logout();
    let timeout = sessionStorage.getItem("sesson_logout_for_api")
    clearPushNotificationApi(timeout, null)
    localStorage.clear()
    sessionStorage.clear()
    sessionStorage.removeItem("sesson_logout_for_api");

    const sideMenu = document.getElementById("kt_body");
    if (sideMenu.classList.contains("aside-minimize")) {
      sideMenu.classList.remove("aside-minimize");
    }

  }

  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(Logout);
