// convert date function
export function DateConvertFunction(data, type) {

  const converted_date = new Date(data)
  let day_ = ("0" + converted_date.getDate()).slice(-2)
  let month_ = ("0" + (converted_date.getMonth() + 1)).slice(-2)
  let year_ = converted_date.getFullYear()
  const monthlist = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
  const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const monthlistFull = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  // time
  let hours =
    converted_date.getHours() > 12
      ? converted_date.getHours() - 12
      : converted_date.getHours()
  let am_pm = converted_date.getHours() >= 12 ? "PM" : "AM"
  let minutes =
    converted_date.getMinutes() < 10
      ? "0" + converted_date.getMinutes()
      : converted_date.getMinutes()

  let hrs = hours === 0 ? 12 : hours
  let time = hrs + ":" + minutes + " " + am_pm

  if (type === 1) {
    return time
  }
  if (type === 2) {
    return day_ + "-" + month_ + "-" + year_
  }
  if (type === 3) {
    return day_ + "-" + month_ + "-" + year_ + "  |" + time
  }
  if (type === 4) {
    return monthlist[converted_date.getMonth()] + " " + day_ + ", " + year_
  }
  if (type === 5) {
    return monthlist[converted_date.getMonth()] + " " + day_ + ", " + year_ + "  |" + time
  }
  if (type === 6) {
    return weekday[converted_date.getDay()] + ", " + day_ + " " + monthlist[converted_date.getMonth()] + " " + year_ + ", " + time
  }
  if (type === 7) {
    return day_ + " " + monthlist[converted_date.getMonth()]
  }
  if (type === 8) {
    let hrs = hours === 0 ? 12 : hours
    let conv_time = hrs + ":" + minutes + " " + am_pm
    return monthlist[converted_date.getMonth()] + " " + day_ + ", " + year_ + "  |" + conv_time
  }
  if (type === 9) {
    let hrs = hours === 0 ? 12 : hours
    let seconds = ("0" + converted_date.getSeconds()).slice(-2)

    return day_ + "/" + monthlist[converted_date.getMonth()] + "/" + year_ + " " + ("0" + hrs).slice(-2) + ":" + minutes + ":" + seconds + " " + am_pm
  }
  if (type === 10) {
    return converted_date
  }
  if (type === 11) {

    let incomingdate = data.split("-")
    const monthNameFull = monthlistFull[Number(incomingdate[1])-1]
    let dayname = incomingdate[2]
    let yearnmae = incomingdate[0]
    let formatedate = monthNameFull + " " + dayname + "," + yearnmae
    let dateToshow = new Date(formatedate);
    return monthlist[dateToshow.getMonth()] + " " + dateToshow.getDate() + ", " + dateToshow.getFullYear()
  }
  if (type === 12) {
    return year_ + "-" + month_ + "-" + day_
  }

}


// This function checks field visibility state
export function checkFieldVisibility(childrenElements, formValue, commonId) {
  let isElementHidden = false
  let hidden = childrenElements.Meta.Hidden
  if (hidden === true) {
    // If the hidden field is true then set isFieldHidden = true (element is hidden)
    isElementHidden = true;
  } else {
    if (hidden === false) {
      // If the hidden field is false then set isFieldHidden = false (element is visible)
      isElementHidden = false;
    } else {
      // If the Hidden field contains conditions then we need to 
      // check those conditions to set element visibility
      let operand = hidden.groups[0].operand

      // Mapping through the element array
      // eslint-disable-next-line array-callback-return
      hidden.groups[0].elements.map((elem) => {
        // Get the value from the formValue to which will be 
        // compared with condition value according to the operator
        let elemValue = commonId ? formValue[elem.uuid]?.[commonId] : formValue[elem.uuid]

        if (elemValue === "" || elemValue === undefined) {
          // If value got empty / undefined then set isFieldHidden = true (element is hidden)
          isElementHidden = true;
        } else {
          // Result of comparison will be pushed to following array
          let conditionArray = []

          // Mapping through the conditions array
          // eslint-disable-next-line array-callback-return
          elem.conditions.map((condition) => {
            // Switch-case to compare values as per operator i.e eq, lt, lte, gt, gte, contains
            switch (condition.operator) {
              case "eq":
                // Equal to
                let isEQ = elemValue === condition.value;
                conditionArray.push(isEQ);
                break;

              case "lt":
                // Less than
                let isLT = Number(elemValue) < Number(condition.value)
                conditionArray.push(isLT);
                break;

              case "lte":
                // Less than or equal to
                let isLTE = Number(elemValue) <= Number(condition.value);
                conditionArray.push(isLTE);
                break;

              case "gt":
                // Greater than
                let isGT = Number(elemValue) > Number(condition.value);
                conditionArray.push(isGT);
                break;

              case "gte":
                // Greater than or equal to
                let isGTE = Number(elemValue) >= Number(condition.value);
                conditionArray.push(isGTE);
                break;

              case "contains":
                // Contains
                let isContains = elemValue.includes(condition.value);
                conditionArray.push(isContains);
                break;

              default:
                break;
            }
          })

          // Checking operand
          if (operand === "OR") {
            // If at least one element of the array is true, the OR condition will be satisfied
            let isOneTrue = conditionArray.some(val => val === true);
            isElementHidden = !isOneTrue;
          } else if (operand === "AND") {
            // If all elements of array will be true which satisfies AND condition
            let isAllTrue = conditionArray.every(val => val === true);
            isElementHidden = !isAllTrue;
          } else {
            // Otherwise set isFieldHidden = true (element is hidden)
            isElementHidden = true;
          }
        }
      })
    }
  }
  return isElementHidden;
}


/** 14/04/2022 
 * This function return the browser name
 */
export function getBroserName() {
  if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
    return 'Opera'
  }
  else if (navigator.userAgent.indexOf("Edg") !== -1) {
    return 'Edge'
  }
  else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    return 'Chrome'
  }
  else if (navigator.userAgent.indexOf("Safari") !== -1) {
    return 'Safari'
  }
  else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    return 'Firefox'
  }
  else if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode === true)) {
    return 'IE'
  }
  else {
    return 'Unknown'
  }
}

/** Function generate Heading/Label for the input fields with/without link
 * @param {object} elem Element data 
 * @param {string} type Type HEADING/LABEL/TEXT/NOTE
 * @returns label string
 */
 export function generateLableOrLink(elem, type) {
  let label = ``
  let t = ""
  let newString = ""
  let entityRanges = []
  let entityMap = {}

  if (type == "HEADING") {
    t = elem.Meta.Heading?.blocks?.[0].text
    newString = elem.Meta.Heading?.blocks?.[0].text
    entityRanges = elem.Meta.Heading?.blocks?.[0].entityRanges
    entityMap = elem.Meta.Heading?.entityMap
  } else if (type == "LABEL") {
    t = elem.Meta.Label?.blocks?.[0].text ? elem.Meta.Label?.blocks?.[0].text : elem.Meta.Label
    newString = elem.Meta.Label?.blocks?.[0].text ? elem.Meta.Label?.blocks?.[0].text : elem.Meta.Label
    entityRanges = elem.Meta.Label?.blocks?.[0].entityRanges
    entityMap = elem.Meta.Label?.entityMap
  } else if (type == "TEXT") {
    t = elem.Meta.Text?.blocks?.[0].text ? elem.Meta.Text?.blocks?.[0].text : elem.Meta.Text
    newString = elem.Meta.Text?.blocks?.[0].text ? elem.Meta.Text?.blocks?.[0].text : elem.Meta.Text
    entityRanges = elem.Meta.Text?.blocks?.[0].entityRanges
    entityMap = elem.Meta.Text?.entityMap
  } else if (type == "NOTE") {
    t = elem.Meta.Note?.blocks?.[0].text ? elem.Meta.Note?.blocks?.[0].text : elem.Meta.Note
    newString = elem.Meta.Note?.blocks?.[0].text ? elem.Meta.Note?.blocks?.[0].text : elem.Meta.Note
    entityRanges = elem.Meta.Note?.blocks?.[0].entityRanges
    entityMap = elem.Meta.Note?.entityMap
  }

  if (entityRanges) {
    entityRanges.forEach(er => {
      let substring = t.substr(er.offset, er.length)
      let link = entityMap[er.key]?.data.url
      let replaceWithStr = `<span class="open-live-link"  data-livelink="${link}" >${substring}</span>`
      newString = newString.replace(substring, replaceWithStr)
    });
  }
  label = newString

  return label
}