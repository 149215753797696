import React from "react";
import { Footer } from "./footer/Footer";
import { AdminHeader } from "../../../app/pages/AdminPages/AdminHeader";

export function AdminLayout({ children }) {

    return (
        <>
            <AdminHeader />
            <div className="container">
                {children}
            </div>
            <div className="admin-footer">
                <Footer />
            </div>

        </>
    )
}
