import React, { useState, useEffect } from "react"
import CloseIcon from "@material-ui/icons/Close"
import { Dialog, Typography, withStyles, Icon, IconButton, DialogTitle as MuiDialogTitle, DialogContent as MuiDialogContent, DialogActions as MuiDialogActions } from "@material-ui/core"

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
})
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    )
})
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions)

export const CommonPopup = (props) => {
    const [errorPopupStatus, seterrorPopupStatus] = React.useState(false)
    const [openYesNoPopup, setopenYesNoPopup] = useState(false);
    const [popupMessageForYesNo, setpopupMessageForYesNo] = useState("");

    useEffect(() => {
        seterrorPopupStatus(props.popupstatus ? props.popupstatus : false)
    }, [props.popupstatus])

    useEffect(() => {
        setopenYesNoPopup(props.popupstatus_yes_no ? props.popupstatus_yes_no : false)
        setpopupMessageForYesNo(props.message_yes_no)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.popupstatus_yes_no])

    const handleClosesError = () => {
        seterrorPopupStatus(false)
        props.close_popup()
    }

    const handleCloseYesNo = () => {
        setopenYesNoPopup(false)
        props.close_popup_yes_no("no")
    }

    const logoutClicked = () => {
        setopenYesNoPopup(false)
        props.close_popup_yes_no("yes")
    }

    return (
        <>
            <Dialog
                onClose={handleClosesError}
                aria-labelledby="customized-dialog-title"
                open={errorPopupStatus}
                className="success-popup"
            >
                <DialogContent dividers className="text-center p-10">
                    <div className="success-icon">
                        <Icon className="fa fa-exclamation-triangle" />
                    </div>
                    <h2 className="font-weight-bold m-10 success-head">Alert!</h2>
                    <p className="success-text mb-10">
                        Your assigned Doctor / Study Coordinator has not yet joined the conference. We'll notify you once they start the meeting.
                    </p>
                    <button type="submit" onClick={handleClosesError} className="btn btn-primary mr-5 save-btn">
                        Ok
                    </button>
                </DialogContent>
            </Dialog>

            {/* with yes no button */}
            <Dialog
                onClose={handleCloseYesNo}
                aria-labelledby="customized-dialog-title"
                open={openYesNoPopup}
                className="log-out-wrap"
            >
                <DialogTitle className="popup-heading">Alert!</DialogTitle>
                <DialogContent dividers className="middle-content">
                    <Typography gutterBottom className="logut-content">
                        {popupMessageForYesNo}
                    </Typography>
                </DialogContent>
                <DialogActions className="btn-wrapper">
                    <div className="card-toolbar">
                        <button className="btn btn-secondary mr-6" onClick={handleCloseYesNo} >
                            No
                        </button>
                        <button
                            type="submit"
                            onClick={logoutClicked}
                            className="btn btn-danger logout-btn"
                        >
                            Yes
                        </button>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
}
