/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useRef } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
export function AsideMenuList({ layoutProps }) {
  const wrapperRef = useRef(null);
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };
  // to close dropdown if its still open

  const onClickSideMenu = () => {
    const dropdownStatus = document.getElementsByClassName("MuiPopover-paper");

    if (dropdownStatus) {
      for (var i = 0; i < dropdownStatus.length; i++) {
        dropdownStatus[i].classList.add("hidefooter");

      }

    }

  }

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps?.ulClasses}`} ref={wrapperRef} onClick={onClickSideMenu}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >

          <div>
            <div data-tut="dashboard_" style={{ width: '90%' }}>
              <NavLink className="menu-link" to="/dashboard">
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip
                      id="user-notification-tooltip"

                    >
                      Dashboard
                    </Tooltip>
                  }
                >
                  <span className="svg-icon menu-icon" >
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Design/dashboard-active.svg")} />
                  </span>
                </OverlayTrigger>
                <span className="menu-text">Dashboard</span>
              </NavLink>
            </div>
          </div>

        </li>

        <li className={`menu-item ${getMenuItemActive("/my-calendar", false)}`}
          aria-haspopup="true">
          <div>
            <div data-tut="study_cal" style={{ width: '90%' }}>

              <NavLink to="/my-calendar" data-tut="study_cal" >
                {/* <Link> */}
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip
                      id="user-notification-tooltip"

                    >
                      Calendar
                    </Tooltip>
                  }
                >
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Design/study.svg")} />
                  </span>
                </OverlayTrigger>
                <span >Calendar</span>
              </NavLink>
            </div>
          </div>

        </li>





        <li
          className={`menu-item ${getMenuItemActive("/my-documents", false)}`}
          aria-haspopup="true">
          <div>
            <div data-tut="my_doc" style={{ width: '90%' }}>
              <NavLink to="/my-documents" >
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip
                      id="user-notification-tooltip"
                    >
                      Study Consents
                    </Tooltip>
                  }
                >
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Design/document.svg")} />
                  </span>
                </OverlayTrigger>
                <span>Study Consents</span>
              </NavLink>
            </div>
          </div>

        </li>
        <li
          className={`menu-item ${getMenuItemActive("/my-form", false)} ${getMenuItemActive("/form-details", false)}`}
          aria-haspopup="true">
          <div>
            <div data-tut="Myform_" style={{ width: '90%' }}>
              <NavLink to="/my-form" >
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip
                      id="user-notification-tooltip"

                    >
                      Visit Forms
                    </Tooltip>
                  }
                >
                  <span className="svg-icon menu-icon" >
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Design/NO-form.svg")} />
                  </span>
                </OverlayTrigger>
                <span>Visit Forms</span>
              </NavLink>
            </div>
          </div>

        </li>
        <li
          className={`menu-item ${getMenuItemActive("/my_notification", false)}`}
          aria-haspopup="true">
          <div>
            <div data-tut="my_notification" style={{ width: '90%' }}>
              <NavLink to="/notification" >
                {/* <Link> */}
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip
                      id="user-notification-tooltip"

                    >
                      Notifications
                    </Tooltip>
                  }
                >
                  <span className="svg-icon menu-icon" >
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Design/notification.svg")} />
                  </span>
                </OverlayTrigger>
                <span>Notifications</span>
              </NavLink>
            </div>
          </div>

        </li>
        <li className={`menu-item ${getMenuItemActive("/my-stipends", false)}`}
          aria-haspopup="true">
          <div>
            <div data-tut="my_stip" style={{ width: '90%' }}>
              <NavLink to="/my-stipends">
                {/* <Link> */}
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip
                      id="user-notification-tooltip"

                    >
                      Stipends
                    </Tooltip>
                  }
                >
                  <span className="svg-icon menu-icon" >
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Design/stipend.svg")} />
                  </span>
                </OverlayTrigger>
                <span>Stipends</span>
              </NavLink>
            </div>
          </div>

        </li>

        {/* temporary on hold */}
        <li className={`menu-item ${getMenuItemActive("/site-info", false)}`}
          aria-haspopup="true">
          <div>
            <div data-tut="Help_commun" style={{ width: '90%' }}>

              <NavLink to="/site-info" >
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip
                      id="user-notification-tooltip"

                    >
                      Help & Communication
                    </Tooltip>
                  }
                >
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Design/help.svg")} />
                  </span>
                </OverlayTrigger>
                <span>Help & Communication</span>

              </NavLink>
            </div>
          </div>

        </li>
        {/*end::1 Level*/}
      </ul>
      <div className="set-position">
        <div className="side-logo1">
          <img src={toAbsoluteUrl("/media/logos/RealTime_Logo_My Study Manager_Horizontal-White.png")} alt="" />
        </div>
      </div>
      <div className="set-position">
        <div className="side-logo">
          <img src={toAbsoluteUrl("/media/misc/white-logo--.png")} alt="" />
        </div>
      </div>
      {/* end::Menu Nav */}
    </>
  );
}
