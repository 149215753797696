import React from "react";
import { Icon } from "@material-ui/core"
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log("error is", error)
    }
    reloadMethod() {
        console.log("inside reload")
        // this.props.history.push("/");
    }
    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <>
                <div style={{textAlign:"center",paddingTop:"10%"}}>

                    <img src="/media/misc/no-data.svg" alt="" />
                    <h2>Something went wrong.</h2>
                    <p>Please try again</p>
                </div>
            </>

        }

        return this.props.children;
    }
}
export default ErrorBoundary