import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../_core/AppLayout";
import { toAbsoluteUrl } from "../../../_helpers";
import { useSelector, shallowEqual } from "react-redux"


export function Brand() {
  const uiService = useHtmlClassService();
  let logo = useSelector((state) => state.auth.logo, shallowEqual)
  let endPointUrl = useSelector((state) => state.auth.end_point_url, shallowEqual)

  const logoUrl = endPointUrl + logo




  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo()
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Brand */}
      <div
        className={`brand flex-column-auto main-logo ${layoutProps.brandClasses}`}
        id="kt_brand"
      >
        {/* begin::Logo */}
        <Link to="" className="brand-logo">
          {/* <img alt="logo" src={layoutProps.headerLogo}/> */}
          <img style={{}} src={logo ? logoUrl : layoutProps.headerLogo} alt="" />

        </Link>
        {/* end::Logo */}

        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}
            <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
              <span className="svg-icon svg-icon-xl">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/menu.svg")} />
              </span>
            </button>
            {/* end::Toolbar */}
          </>
        )}
      </div>
      {/* end::Brand */}
    </>
  );
}
