import React, { useRef, } from "react";
import IdleTimer from "react-idle-timer";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux"
import * as auth from "../app/modules/Auth/_redux/authRedux"
import { clearPushNotificationApi } from "./modules/Auth/_redux/authCrud";

const AutoLogout = ({ admin_status }) => {
    const dispatch = useDispatch()
    // to clear login if user close the tab
    let history = useHistory();
    const idleTimer = useRef(null);
    // when we do any events 
    const onActive = () => {
    };

    // when IDLE time starts
    const onIdle = () => {
        if (admin_status === "Patient") {
            const id = sessionStorage.getItem("id")
            clearPushNotificationApi(true, id)
            dispatch(auth.actions.logout())
            localStorage.clear()
            sessionStorage.clear()
            sessionStorage.setItem("sesson_logout_for_api", "true")
            sessionStorage.setItem("sesson_logout", "true")
            history.push('/')
        }
        if (admin_status === "Admin" || admin_status === "SuperAdmin") {
            const id = sessionStorage.getItem("id")
            clearPushNotificationApi(true, id)
            dispatch(auth.actions.logout())
            localStorage.clear()
            sessionStorage.clear()
            sessionStorage.setItem("sesson_logout", "true")
            history.push("/")
        }
    };

    return (
        <>
            {/* to find out IDLE TIME of system */}
            <IdleTimer
                ref={idleTimer}
                onActive={onActive}
                onIdle={onIdle}
                debounce={250}
                timeout={1200000} // 20 mins=12000000 
            />
        </>
    );
}
export default AutoLogout;